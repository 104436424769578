import React from "react";
import { Link, Route, Routes } from 'react-router-dom';
import Loading from "../../components/Loading/Loading";
import Noticia from "./Noticia/Noticia";
import { news } from "./news";
import './Noticies.scss';

export default class Noticies extends React.Component<{}, { id: number; data: any }> {
  constructor(props: any) {
    super(props);
    const path = window.location.pathname.match(/\d+/);
    this.state = {
      id: path ? Number(path) : 0,
      data: null
    };
  }

  componentDidMount() {
    if (this.state.data !== null) return;
    this.fetchData();
  }

  fetchData = async (id?: string) => {
    if (this.state.data !== null) return;
    try {
      const response = await fetch(`https://api.castellersdemollet.cat/noticies`);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      this.setState({
        data: data
      });
    } catch (error: any) {
      console.error('Fetch error:', error);
    }
  };

  render(): React.ReactNode {
    console.log(this.state.data);
    return (
      <main className='noticies'>
        <Routes>
          <Route path='/' element={
            <React.Suspense fallback={<Loading />}>
              <h1>Noticies</h1>
              <ul>
                {
                  this.state.data?.map((n: any) => (
                    <Link key={n.id} to={`/noticies/${n.id}`} onClick={
                      () => {
                        this.setState({ id: n.id })
                        window.scrollTo(0, 0)
                      }
                    }>
                      <Noticia id={n.id} data={n} preview />
                    </Link>
                  ))
                }
              </ul>
            </React.Suspense>
          } />
          {
            this.state.id > 0 &&
            <Route path='/*' element={
              <React.Suspense fallback={<Loading />}>
                <Noticia id={this.state.id} data={
                  this.state.data?.find((n: any) => n.id === this.state.id)
                } />
                <aside>
                  <h2>Altres Noticies</h2>
                  <ul>
                    {
                      [1, 2, 3, 4, 5].filter((i) => i !== this.state.id).map((i) => (
                        <li key={i}>
                          <Link to={`/noticies/${i}`} onClick={
                            () => {
                              this.setState({ id: i })
                              window.scrollTo(0, 0)
                            }
                          }>
                            {
                              news.find((n) => n.id === i)?.header.title ?? 'Undefined'
                            }
                          </Link>
                        </li>
                      ))
                    }
                  </ul>
                </aside>
              </React.Suspense>
            } />
          }
        </Routes>
      </main>
    );
  }
}
import React from 'react';
import { Link, Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import './views/Dashboard/Dashboard.scss';
import Loading from './components/Loading/Loading';
import Nav from './views/Dashboard/Nav/Nav';
const Tecnica = React.lazy(() => import('./views/Dashboard/Tecnica/Tecnica'));
const Permissions = React.lazy(() => import('./views/Dashboard/Permissions/Permissions'));
const Comunicació = React.lazy(() => import('./views/Dashboard/Comunicació/Comunicació'));
const EditorSecciones = React.lazy(() => import('./views/Dashboard/EditorSecciones/EditorSecciones'));
const EditorCalendari = React.lazy(() => import('./views/Dashboard/EditorCalendari/EditorCalendari'));
const EditorContingutHome = React.lazy(() => import('./views/Dashboard/EditorContingut/EditorContingutHome.routes'));

export default class Dashboard extends React.Component {
	render(): React.ReactNode {
		return (
			<Router >
				<Nav />
				<main className='dash'>
					<Routes>
						<Route path='/' element={
							<React.Suspense fallback={<Loading />}>
								<div className='home'>
									<ul>
										<li>
											<Link to='/calendari'>
												<h2>Calendari</h2>
												<p>Modifica, crea o elimina esdeveniments al calendari anual.</p>
											</Link>
										</li>
										<li>
											<Link to='/noticies'>
												<h2>Noticies</h2>
												<p>Modifica, crea o elimina esdeveniments al calendari anual.</p>
											</Link>
										</li>
										<li>
											<Link to='/seccions'>
												<h2>Seccions</h2>
												<p>Modifica, crea o elimina esdeveniments al calendari anual.</p>
											</Link>
										</li>
										<li>
											<Link to='/contingut'>
												<h2>Contingut</h2>
												<p>Modifica, crea o elimina esdeveniments al calendari anual.</p>
											</Link>
										</li>
									</ul>
								</div>
							</React.Suspense>
						} />
						<Route path='/contingut/*' element={
							<React.Suspense fallback={<Loading />}>
								<EditorContingutHome />
							</React.Suspense>
						} />
						<Route path='/perms' element={
							<React.Suspense fallback={<Loading />}>
								<Permissions />
							</React.Suspense>
						} />
						<Route path='/comunicacio' element={
							<React.Suspense fallback={<Loading />}>
								<Comunicació />
							</React.Suspense>
						} />
						<Route path='/tecnica' element={
							<React.Suspense fallback={<Loading />}>
								<Tecnica />
							</React.Suspense>
						} />
						<Route path='/calendari' element={
							<React.Suspense fallback={<Loading />}>
								<EditorCalendari />
							</React.Suspense>
						} />
						<Route path='/secciones' element={
							<React.Suspense fallback={<Loading />}>
								<EditorSecciones />
							</React.Suspense>
						} />
						<Route path='/me' element={
							<React.Suspense fallback={<Loading />}>
								<EditorContingutHome />
							</React.Suspense>
						} />
					</Routes>
				</main>
			</Router>
		);
	}
};
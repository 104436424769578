import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Dashboard from './Dashboard';
import Loading from './components/Loading/Loading';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
const dash = true;
const hostname = window.location.hostname.split('.').some((x) => ['localhost'].includes(x));

if (dash && hostname) {
  if (window.location.hostname.split('.')[0] === 'cdm-4d8') {
    const theme = document.cookie.replace(
      /(?:(?:^|.*;\s*)theme\s*\=\s*([^;]*).*$)|^.*$/,
      "$1",);
    root.render(
      <React.StrictMode>
        <div className="App" data-theme={theme}>
          <React.Suspense fallback={<Loading />}>
            <Dashboard />
          </React.Suspense>
        </div>
      </React.StrictMode>
    );
  } else {
    root.render(
      <React.StrictMode>
        <div className="App">
          <App />
        </div>
      </React.StrictMode>
    );
  }
} else {
  root.render(
    <React.StrictMode>
      <div className="App">
        <App />
      </div>
    </React.StrictMode>
  );
}